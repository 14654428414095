import React from 'react';
import Tvunitwithshelfandstorageshopdetails from '../components/Alltvcabinet/Tvunitwithshelfandstorageshopdetails';
import TvunitwithshelfandstorageArea from '../components/Alltvcabinet/TvunitwithshelfandstorageArea';
import Header from "../components/Header";
import Footer from "../components/Footer";

function Tvunitwithshelfandstorage() {
	return (
		<>
			<Header />
			<main>
				<Tvunitwithshelfandstorageshopdetails />
				<TvunitwithshelfandstorageArea />
			</main>
			<Footer />
		</>
	)
}

export default Tvunitwithshelfandstorage;
