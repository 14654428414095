import React from 'react';
import { Link } from 'react-router-dom'
import Slider from 'react-slick'
import HomeCounter from '../hometwo/HomeCounter'

function BreederAdoption() {

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          speed: 1000,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          speed: 1000,
        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          speed: 1000,
        }
      },
    ]
  }
  return (
    <section className="inner-breeder-area breeder-bg inner-area-padding">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-xl-4 col-lg-9 col-md-12 col-sm-9">
            <div className="adoption-content">
              <h2 className="title main-slider-font-weight">How <span>It</span> Works ?</h2>
              <p className="text-justify">Reach out to us via WhatsApp or give us a call to kickstart your ultimate home design journey.</p>
              <div className="adoption-list">
                <ul>
                  <li><i className="flaticon-tick" /> Meet the designer</li>
                  <li><i className="flaticon-tick" /> Finalise the design </li>
                  <li><i className="flaticon-tick" /> Watch the Magic Unfold</li>
                  <li><i className="flaticon-tick" /> Final Installation</li>
                  <li><i className="flaticon-tick" /> Move in and enjoy!</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-xl-8 col-lg-9 col-md-12 col-sm-9">
            <div className="breeder-info-wrap">
              <div className="row no-gutters justify-content-center">
                <div className="col-md-6">
                  <Slider className="breeder-active slick"{...settings}>
                    <div className="breeder-item">
                      <img src="img/moduler_banner/a.png" alt="" />
                    </div>
                    <div className="breeder-item">
                      <img src="img/moduler_banner/b.png" alt="" />
                    </div>
                    <div className="breeder-item">
                      <img src="img/moduler_banner/c.png" alt="" />
                    </div>
                    <div className="breeder-item">
                      <img src="img/moduler_banner/d.png" alt="" />
                    </div>
                    <div className="breeder-item">
                      <img src="img/moduler_banner/e.png" alt="" />
                    </div>
                  </Slider>
                </div>

                <div className="col-md-6">
                  <div className="breed-services-info" style={{ backgroundImage: 'url("img/bg/bg.png")' }}>
                    <h2 className="sub-title main-slider-font-weight">CUSTOMISED SOLUTIONS </h2>
                    <h3 className="title main-slider-font-weight">Get the experience you'll love</h3>
                    <p>Reach our advisors to get designs and quotations</p>
                    <Link to="/contacts" className="btn main-slider-font-weight">Contact Us </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="counter-area mt-70">
          <HomeCounter />
        </div>
      </div>

    </section>
  )
}
export default BreederAdoption;
