import React from 'react';
import TvunitwithcabinetShopDetails from '../components/Alltvcabinet/TvunitwithcabinetShopDetails';
import TvunitwithcabinetArea from '../components/Alltvcabinet/TvunitwithcabinetArea';
import Header from "../components/Header";
import Footer from "../components/Footer";

function Tvunitwithcabinet() {
	return (
		<>
			<Header />
			<main>
				<TvunitwithcabinetShopDetails />
				<TvunitwithcabinetArea />
			</main>
			<Footer />
		</>
	)
}

export default Tvunitwithcabinet;
