import React from 'react';
import WallmountedtvunitShopDetails from '../components/Alltvcabinet/wallmountedtvunitShopDetails';
import WallmountedtvunitArea from '../components/Alltvcabinet/wallmountedtvunitArea';
import Header from "../components/Header";
import Footer from "../components/Footer";

function Wallmountedtvunitpage() {
	return (
		<>
			<Header />
			
			<main>
				<WallmountedtvunitShopDetails />
				<WallmountedtvunitArea />
			</main>
			<Footer />
		</>
	)
}

export default Wallmountedtvunitpage;
