import React from 'react';
import Tvunitwithdrawershopdetails from '../components/Alltvcabinet/Tvunitwithdrawershopdetails';
import TvunitwithdrawerArea from '../components/Alltvcabinet/TvunitwithdrawerArea';
import Header from "../components/Header";
import Footer from "../components/Footer";

function Tvunitwithdrawer() {
	return (
		<>
			<Header />
			<main>
				<Tvunitwithdrawershopdetails />
				<TvunitwithdrawerArea />
			</main>
			<Footer />
		</>
	)
}

export default Tvunitwithdrawer;
