import React from 'react';
import Wallmountedtvunitwithshelfshopdetails from '../components/Alltvcabinet/Wallmountedtvunitwithshelfshopdetails';
import WallmountedtvunitwithshelfArea from '../components/Alltvcabinet/WallmountedtvunitwithshelfArea';
import Header from "../components/Header";
import Footer from "../components/Footer";

function Wallmountedtvunitwithshelf() {
	return (
		<>
			<Header />
			<main>
				<Wallmountedtvunitwithshelfshopdetails />
				<WallmountedtvunitwithshelfArea />
			</main>
			<Footer />
		</>
	)
}

export default Wallmountedtvunitwithshelf;
