import React from 'react';
import Sheeshamwoodtvunitshopdetails from '../components/Alltvcabinet/Sheeshamwoodtvunitshopdetails';
import SheeshamwoodtvunitArea from '../components/Alltvcabinet/SheeshamwoodtvunitArea';
import Header from "../components/Header";
import Footer from "../components/Footer";

function Sheeshamwoodtvunit() {
	return (
		<>
			<Header />
			<main>
				<Sheeshamwoodtvunitshopdetails />
				<SheeshamwoodtvunitArea />
			</main>
			<Footer />
		</>
	)
}

export default Sheeshamwoodtvunit;
