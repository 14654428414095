import React from "react";

function Returnandrefunds() {
  return (
    <section className="contact-area pt-110 pb-110">
      <div className="container">
        <div className="container-inner-padding">
          <div className="row justify-content-center justify-content-lg-between">
            <div>
              <div className="contact-title mb-20">
                <h2 className="main-slider-font-weight">
                  Return and Refunds – Maheshwari Furniture
                </h2>
                <p>
                  How do I Cancel an item purchased on Maheshwari Furniture?
                </p>
                <ul>
                  <li className="return-policy-list">
                    Cancellation in case of Wrong Product: If the product does
                    not comply with the specifications as per your original
                    order, raise the issue immediately at the{" "}
                    <a
                      href="mailto:maheshwarifurniturejpr@gmail.com"
                      className="terms-of-use-a-color"
                    >
                      maheshwarifurniturejpr@gmail.com
                    </a>
                  </li>
                  <li className="return-policy-list">
                    Cancellation in case of Change in Requirement: You can
                    cancel your order for any product within 24 hours of placing
                    it, and a full refund will be initiated pertaining to the
                    order. However, cancellation can only be done if the product
                    is in production / pre-production stage. Cancellation is not
                    possible after a product has been dispatched from the
                    national/regional warehouse. Post the 24-hour window, no
                    cancellation will be entertained.
                  </li>
                  <li className="return-policy-list">
                    You must address or submit the damage/defect concern within
                    72 hours. Any queries for damage/defect will not be
                    entertained once the timeline is breached.
                  </li>
                  <li className="return-policy-list">
                    For fragile products, customers are requested to share an
                    unboxing video in case of damage/defect.
                  </li>
                  <li className="return-policy-list">
                    For more details, please get in touch with us at our contact
                    us page.
                  </li>
                </ul>
              </div>
              <div>
                <h2 className="main-slider-font-weight">Return & Refund:</h2>
                <p>
                  If you are eligible for any return, the same shall be given to
                  you as per the following guidelines:
                </p>
                <ul>
                  <li className="return-policy-list">
                    All refunds & replacement process initiation shall be
                    subject to pick up of all cancelled items from
                    your/customer's premises.
                  </li>
                  <li className="return-policy-list">
                    Post receiving the products back to the warehouse, a refund
                    shall be initiated within 2-3 days.
                  </li>
                  <li className="return-policy-list">
                    Depending on the orders, the processing charges might be
                    deducted.
                  </li>
                </ul>
              </div>
              <div className="mt-20 ">
                <h2 className="main-slider-font-weight">Warranties:</h2>
                <p>
                  Our products come with 12 months* warranty period, which
                  covers manufacturing/workmanship defects issues that occur
                  during the warranty period. The warranty applies to furniture
                  used under normal household conditions.
                  <br />
                  This warranty applies to the products purchased after 1st
                  April 2024
                </p>
                <p>This limited warranty does not apply to the following:</p>
                <ul>
                  <li className="return-policy-list">
                    Normal wear and tear of the product over prolonged use
                  </li>
                  <li className="return-policy-list">
                    Small cuts, scratches or damages due to wrong cleaning
                    methods or impacts/accidents
                  </li>
                  <li className="return-policy-list">
                    Damage caused due to incorrect installation/assembly by the
                    customer
                  </li>
                  <li className="return-policy-list">
                    Products that have been stored, assembled, or installed
                    incorrectly, used inappropriately or cleaned using the wrong
                    cleaning methods or cleaning products. (e.g., upholstery
                    furniture will lighten over time if it's exposed to direct
                    sunlight)
                  </li>
                  <li className="return-policy-list">
                    Cracks developed due to the displacement of the product
                  </li>
                  <li className="return-policy-list">
                    Decaying of wood due to consistent exposure to water and
                    weather conditions
                  </li>
                  <li className="return-policy-list">
                    In response to seasonal climate variations, solid wood will
                    contract and expand throughout the life of the product, and
                    it does not cover under the warranty section (for solid wood
                    furniture).
                  </li>
                  <li className="return-policy-list">
                    Joint lines, wood grains, or other issues related to the
                    natural characteristics of wood are not defects and are not
                    covered under warranty. In addition, any claim for seasonal
                    splits or joints opening is limited to the warranty period
                    from the time of purchase (for solid wood furniture).
                  </li>
                  <li className="return-policy-list">
                    Upholstery fabrics such as seat covers have no warranty. For
                    upholstery products, the warranty applies to the frame, and
                    mechanisms, if any
                  </li>

                  <li className="return-policy-list">
                    {" "}
                    Non-standard dry-cleaning procedures or the use of
                    harshchemicals.
                  </li>
                  <li className="return-policy-list">
                    As per industry standards, unevenness of up to 5 mm is
                    accepted widely due to differences in floor and surface
                    levels. This is not covered in the warranty
                  </li>
                </ul>
              </div>
              <div className="mt-20 ">
                <h2 className="main-slider-font-weight">Warranty Contact</h2>
                <p>
                  In case of any issues, please raise a ticket through{" "}
                  <a
                    href="mailto:maheshwarifurniturejpr@gmail.com"
                    className="terms-of-use-a-color"
                  >
                    maheshwarifurniturejpr@gmail.com
                  </a>{" "}
                  and we will resolve your query at the earliest.
                </p>
                <h6 className="main-slider-font-weight">Delivery:</h6>
                <ul>
                  <li className="return-policy-list">
                    Our support and delivery team will be in coordination with
                    you for a hassle-free installation process.
                  </li>
                  <li className="return-policy-list">
                    Delivery is applicable as per a visit to your ship-to
                    address. In case of a missed delivery, (from a customer)/ an
                    extra visiting charge would be applicable for later
                    installation.
                  </li>
                  <li className="return-policy-list">
                    Our delivery partner shall only deliver the product to the
                    ground floor in the absence of a service lift in your
                    apartment. (if applicable)
                  </li>
                  <li className="return-policy-list">
                    Extra charges applicable for weekend or specific time
                    deliveries.
                  </li>
                  <li className="return-policy-list">
                    Once the order has reached your nearest delivery center and
                    you fail to receive the products, we would hold the products
                    for 10 days, after this time line, Maheshwari Furniture
                    shall be free to charge for holding the products for longer.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Returnandrefunds;
