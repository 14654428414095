import React from 'react';
import { Link } from 'react-router-dom'

function Download() {
    return (
        <section className="appie-how-it-work-area pt-20 pb-100">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="how-it-work-thumb text-center">
                            <img src="img/moduler_banner/3rd_section.png" alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="appie-how-it-work-content">
                            <h2 className="title main-slider-font-weight">Why <span className="yellow-text"> Maheshwari </span> Furniture ?</h2>
                            <p className="text-justify">We have been crafting customised interior modular solutions since 2015 and offer a comprehensive solution for all modular design needs. Maheshwari Furniture boasts of quality, functionality, durability, design, and the most sophisticated manufacturing processes. Experience the seamless blend of style and convenience that modular solutions bring.</p>
                            <div className="adoption-list">
                                <ul>
                                    <li><i className="flaticon-tick" /><b>Transform Your Living Space</b></li>
                                    <li className='modular-pg-li'>Explore a variety of versatile modular design layouts, including U-shaped, L-shaped, or parallel finishes, to elevate your home decor to new heights.</li>
                                    <li><i className="flaticon-tick" /><b>Luxury in Storage</b></li>
                                    <li className='modular-pg-li'>Indulge in luxurious storage solutions with options like hinged, sliding, or the opulent walk-in wardrobe style, designed to enhance both functionality and aesthetics.</li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <Link to="/about-us" className="btn main-slider-font-weight" data-animation="fadeInUpBig" data-delay=".6s" data-duration="1.2s">About Us </Link>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    );
}

export default Download;

