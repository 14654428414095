import React from 'react';
import TvunitwithcabinetdrawerShopDetails from '../components/Alltvcabinet/TvunitwithcabinetdrawerShopDetails';
import TvunitwithcabinetdrawerArea from '../components/Alltvcabinet/TvunitwithcabinetdrawerArea';
import Header from "../components/Header";
import Footer from "../components/Footer";

function Tvunitwithcabinetdrawer() {
	return (
		<>
			<Header />
			<main>
				<TvunitwithcabinetdrawerShopDetails />
				<TvunitwithcabinetdrawerArea />
			</main>
			<Footer />
		</>
	)
}

export default Tvunitwithcabinetdrawer;
