import React from 'react';
import TvunitwithcupboarddrawersShopDetails from '../components/Alltvcabinet/TvunitwithcupboarddrawersShopDetails';
import TvunitwithcupboarddrawersArea from '../components/Alltvcabinet/TvunitwithcupboarddrawersArea';
import Header from "../components/Header";
import Footer from "../components/Footer";

function Tvunitwithcupboarddrawers() {
	return (
		<>
			<Header />
			
			<main>
				<TvunitwithcupboarddrawersShopDetails />
				<TvunitwithcupboarddrawersArea />
			</main>
			<Footer />
		</>
	)
}

export default Tvunitwithcupboarddrawers;
