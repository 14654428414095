import React from 'react';
import SmalltvunitShopDetails from '../components/Alltvcabinet/SmalltvunitShopDetails';
import SmalltvunitArea from '../components/Alltvcabinet/SmalltvunitArea';
import Header from "../components/Header";
import Footer from "../components/Footer";

function Smalltvunit() {
	return (
		<>
			<Header />
			
			<main>
				<SmalltvunitShopDetails />
				<SmalltvunitArea />
			</main>
			<Footer />
		</>
	)
}

export default Smalltvunit;
